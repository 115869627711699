/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.3.1/dist/jquery.min.js
 * - /npm/npm-modernizr@2.8.3/modernizr.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation-unobtrusive@3.2.10/dist/jquery.validate.unobtrusive.min.js
 * - /npm/jquery-ui-dist@1.12.1/jquery-ui.min.js
 * - /npm/sweetalert@2.1.0/dist/sweetalert.min.js
 * - /npm/rangeslider.js@2.3.2/dist/rangeslider.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
